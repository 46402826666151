import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, NavLink } from "react-router-dom";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { useSelector } from "react-redux";
import { ApiHook } from "../../hooks/apiHook";
import { useQueryClient } from "@tanstack/react-query";
function ShoppingCart({ isLeftMenuVisible }) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [investment, setInvestment] = useState({});
  const [showCartItems, setShowCartItems] = useState(true);
  const navigate = useNavigate();
  const [errors, setError] = useState({});
  const userSelectedCurrency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );

  //------------------------------------- API ---------------------------------------
  const items = ApiHook.CallCartItems(setShowCartItems);
  const addCartMutation = ApiHook.CallAddToCart();
  const removeCartMutation = ApiHook.CallRemoveCartItem();

  useEffect(() => {
    if (items && items.data) {
      const initialInvestment = {};
      items.data.forEach((item) => {
        setError({ [item.name]: "" });
        initialInvestment[item.packageId] = item.quantity;
      });
      setInvestment(initialInvestment);
    }
  }, [items.data]);

  const handleQuantityChange = (event, product) => {
    const newInvestment = {
      ...investment,
      [product.packageId]: event.target.value,
    };

    setInvestment(newInvestment);

    const isValid =
      event.target.value >= product.min &&
      event.target.value <= product.max &&
      event.target.value % 100 === 0;

    if (isValid) {
      addCartMutation.mutate(
        { packageId: product.packageId, amount: event.target.value },
        {
          onSuccess: (res) => {
            if (res.status) {
              queryClient.invalidateQueries({ queryKey: ["cart-items"] });
            }
          },
        }
      );
    } else {
      setError({ [product.name]: t("invalid") });
    }
  };

  const handleRemoveCartItem = (packageId) => {
    removeCartMutation.mutate(
      { packageId: packageId },
      {
        onSuccess: (res) => {
          if (res.status) {
            queryClient.invalidateQueries({ queryKey: ["cart-items"] });
          }
        },
      }
    );
  };

  const totalAmount = items?.data?.reduce(
    (total, product) => total + product.quantity,
    0
  );

  const renderProducts = () => {
    return items?.data?.map((product, index) => (
      <tr key={index}>
        <td>
          <div className="profile_table">
            <img src={product?.image ?? "/images/product2.jpg"} alt="" />
          </div>
          {product?.name}
        </td>
        <td>{`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
          product.min,
          conversionFactor
        )} - ${CurrencyConverter(product.max, conversionFactor)}`}</td>
        <td>
          <div className="checkout_cnt_qty_btn_sec">
            <input
              name={product.name}
              className="checkout_cnt_qty_input"
              type="number"
              value={investment[product.packageId] || ""}
              onChange={(e) => handleQuantityChange(e, product)}
            />
          </div>
          {errors[product.name] && (
            <div style={{ color: "red" }}>{"invalid Amount"}</div>
          )}
        </td>
        <td>
          <button
            className="btn_chekcout_row"
            onClick={() => handleRemoveCartItem(product?.packageId)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </td>
      </tr>
    ));
  };

  const handleCheckout = () => {
    console.log(" =========================== ");
    let isValid = true;

    for (const item of items?.data) {
      console.log(item, investment[item.packageId]);
      if (
        item.price < item.min ||
        item.price > item.max ||
        item.price % 100 !== 0 ||
        investment[item.packageId] !== item.price
      ) {
        isValid = false;
        break;
      }
    }

    if (isValid) {
      navigate("/checkout");
    }
  };

  return (
    <>
      {!isLeftMenuVisible && (
        <>
          {items?.data?.length > 0 && (
            <div className="shopping_footer_cart">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
              >
                <div
                  className="shopping_cart_arrow"
                  onClick={() => setShowCartItems(!showCartItems)}
                  style={{ maxWidth: "0px" }}
                >
                  <i
                    className={
                      showCartItems ? "fa fa-angle-down" : "fa fa-angle-up"
                    }
                  ></i>
                </div>
                <div className="shopping_cart_prd_dtl">
                  <h5>
                    {items?.data?.length} {t("products")}
                  </h5>
                  <p>
                    {items?.data?.map((product) => product.name).join(", ")}
                  </p>
                </div>
                <div className="shopping_cart_prd_amount">
                  <p>{t("totalAmount")}</p>
                  <h5>{`${userSelectedCurrency.symbolLeft} ${CurrencyConverter(
                    totalAmount,
                    conversionFactor
                  )}`}</h5>
                </div>
                <div className="shopping_cart_prd_btn">
                  <button
                    onClick={handleCheckout} // Call the function directly
                    className="btn btn-primary checkout_btn_cart"
                  >
                    {t("checkout")}
                  </button>
                </div>
              </div>
              <div
                className={`shopping_cart_item_showing ${
                  showCartItems ? "show_mn" : ""
                }`}
              >
                <table>
                  <thead>
                    <tr>
                      <th>{t("product_name")}</th>
                      <th>{t("price")}</th>
                      {/* <th>{t("quantity")}</th> */}
                      <th>{t("totalAmount")}</th>
                      <th>{t("action")}</th>
                    </tr>
                  </thead>
                  <tbody>{renderProducts()}</tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default ShoppingCart;
