import React, { useState } from "react";
import { Offcanvas, Table, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import SubmitButton from "../Common/buttons/SubmitButton";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../Currency/CurrencyConverter";
import { reverseNumberDisplay } from "../../utils/currencyNumberDisplay";
import { toast } from "react-toastify";
// import CurrencyInput from "react-currency-input-field";
import { Link } from "react-router-dom";
import Select from "react-select";
const RoiInvestment = ({ show, handleClose, data, setValidity }) => {

  const { t } = useTranslation();
  const [investamount, setInvestAmount] = useState()
  // const queryClient = useQueryClient();
  const [payoutData, setPayoutData] = useState({
    amount: 0,
    transactionPassword: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    amount: null,
    transactionPassword: null,
  });
  const userSelectedCurrency = useSelector(
    (state) => state?.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  const defaultCurrencyJson = useSelector(
    (state) => state?.user?.loginResponse?.defaultCurrency
  );

  const defaultCurrency = defaultCurrencyJson
    ? JSON.parse(defaultCurrencyJson)
    : null;


  const ReinvestMutation = ApiHook.CallReinvest(setValidity);
  const WithdrawMutation = ApiHook.CallWithdrawinvest();


  const investOptions = (data) => {
    return data?.map((item) => ({
      label: `${item['Package.name']} ( ${userSelectedCurrency?.symbolLeft}${item.totalRoiAmount})`,
      value: item?.totalRoiAmount,
      pack: item?.packageId
    }));
  };

  const changeInvest = (item) => {
    setInvestAmount(item.value);
    setPayoutData((prevData) => ({
      ...prevData,
      amount: item.value,
      package: item.pack,
    }));
  };

  const isFormValid = () => {
    return (
      payoutData?.amount > 0 &&
      payoutData?.transactionPassword.trim() !== ""
    );
  };


  const handleChange = (e) => {
    const { id, value } = e.target;
    setPayoutData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrorMessage((prevData) => ({
      ...prevData,
      [id]: null,
    }));
    if (value === null || value === "") {
      setErrorMessage((prev) => ({
        ...prev,
        [id]: "*Required",
      }));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }

  const handleWithdraw = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    let convertAmount;
    if (userSelectedCurrency.id === defaultCurrency.id) {
      convertAmount = reverseNumberDisplay(
        CurrencyConverter(payoutData?.amount, conversionFactor, 0)
      );
    } else {
      convertAmount = reverseNumberDisplay(
        CurrencyConverter(payoutData?.amount, conversionFactor, 1)
      );
    }
    const postData = {
      amount: convertAmount,
      transactionPassword: payoutData?.transactionPassword,
      pack : payoutData?.package
    };
    await WithdrawMutation.mutateAsync(postData, {

      onSuccess: (res) => {
        if (res.data.status) {
          toast.success(t(res.data.data.description))
          setPayoutData({
            amount: "",
            transactionPassword: "",
          });
          handleClose(false);
          setValidity(res.data.data.validity)
        } else {
          if (res?.data?.code === 1015) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              transactionPassword: t(res?.data?.description),
            }));
          } else if (res?.data?.code === 1036) {
            toast.error(t("Update Payout Payment Method"));
          } else if (res?.data?.code === 1073) {
            toast.error(t(res?.data?.description));
          } else {
            toast.error(t(res?.data?.description));
          }
        }
      },
    });
  };

  const handleInvest = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    let convertAmount;
    if (userSelectedCurrency.id === defaultCurrency.id) {
      convertAmount = reverseNumberDisplay(
        CurrencyConverter(payoutData?.amount, conversionFactor, 0)
      );
    } else {
      convertAmount = reverseNumberDisplay(
        CurrencyConverter(payoutData?.amount, conversionFactor, 1)
      );
    }
    const postData = {
      amount: convertAmount,
      transactionPassword: payoutData?.transactionPassword,
      pack : payoutData?.package
    };
    await ReinvestMutation.mutateAsync(postData, {
      onSuccess: (res) => {
        if (res.data.status) {
          toast.success(t(res.data.data.description))
          setPayoutData({
            amount: "",
            transactionPassword: "",
          });
          setValidity(res.data.data.validity)
          handleClose(false);
        } else {
          if (res?.data?.code === 1015) {
            setErrorMessage((prevErrors) => ({
              ...prevErrors,
              transactionPassword: t(res?.data?.description),
            }));
          }
          else {
            toast.error(t(res?.data?.data?.description));
          }
        }
      },
    });
  };

  return (
    <Offcanvas
      show={show}
      placement="end"
      onHide={handleClose}
      scroll={true}
      backdrop={true}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>{t("Roi_Investment")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form onKeyDown={handleKeyDown}>
          <Form.Group className="mb-3 amount-field">

            <Form.Label>
              {t("amount")}
              <span className="text-danger">*</span>
            </Form.Label>
            <Select
              id="amount"
              value={investOptions(data)?.find(
                (item) => item.value === investamount
              )}
              options={investOptions(data)}
              onChange={changeInvest}
              isSearchable={false}
            />

          </Form.Group>
          <Form.Group className="mb-3">
            <label style={{ color: "red" }}>*</label><Form.Label>{t("transactionPassword")}</Form.Label>
            <Form.Control
              id="transactionPassword"
              type="password"
              placeholder={t("transactionPassword")}
              onChange={(e) => handleChange(e)}
              value={payoutData?.transactionPassword}
              required
              isInvalid={errorMessage?.transactionPassword !== null}
            />
            <Form.Control.Feedback type="invalid">
              {errorMessage?.transactionPassword && (
                <Link to="/profile" style={{ color: "red" }}>
                  {errorMessage.transactionPassword}
                </Link>
              )}
            </Form.Control.Feedback>
          </Form.Group>
          <div style={{ display: "flex", margin: "10px" ,gap: "20px"}}>
            <SubmitButton
              isSubmitting={!isFormValid() || WithdrawMutation.isLoading || ReinvestMutation.isLoading}
              click={handleWithdraw}
              text={WithdrawMutation.isLoading ? "Withdrawing..." : "Withdraw"}
              className={"btn btn-primary submit_btn"}
            />
            <SubmitButton
              isSubmitting={!isFormValid() || ReinvestMutation.isLoading || WithdrawMutation.isLoading}
              click={handleInvest}
              text={ReinvestMutation.isLoading ? "ReInvesting..." : "ReInvest"}
              className={"btn btn-primary submit_btn"}
            />
          </div>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default RoiInvestment;
